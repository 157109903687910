import React from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link, useStaticQuery } from "gatsby";


import "../../scss/main.scss";

export default function OurTeam() {
	const {
		site: { 
			siteMetadata: {
				appConfig: {
					domain: appDomain 
				}
			} 
		}
	} = useStaticQuery(
		graphql`
		  query {
            site {
                siteMetadata {
                    appConfig {
                        domain
                    }
                }
            }
        }
	`)

	return (
		<>
			<Helmet>
				<meta charset="UTF-8" />
				<meta http-equiv="X-UA-Compatible" content="IE=edge" />
				
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<title>Edyn Care | Our Team</title>
				<link href="css/main.css" rel="stylesheet" />
				<meta property="og:title" content="Edyn Care | Our Team" />
				<meta property="og:description" content="High quality care in the comfort of your own home." />
				<meta property="og:image" content="https://www.edyn.care/assets/marketing-site/images/open-graph-img.png" />
				<meta property="og:url" content="https://www.edyn.care/our-team" />
				<meta name="twitter:card" content="summary_large_image" />
				<meta property="twitter:domain" content="edyn.care" />
				<meta property="twitter:url" content="https://www.edyn.care/our-team" />
				<meta name="twitter:title" content="Edyn Care | Our Team" />
				<meta name="twitter:description" content="High quality care in the comfort of your own home." />
				<meta name="twitter:image" content="https://www.edyn.care/assets/marketing-site/images/open-graph-img.png" />
			</Helmet>
			<div className="container">
				<Header />
				<main className="our-team-page">
					<section className="approach-grid">
						<div className="approach-post-landing">
							<StaticImage placeholder="blurred"
								className="approach-post-left"
								src="../../static/assets/marketing-site/images/our-approach-01.png"
								objectFit='contain'
							/>
							<div className="landing-content">
								<p className="secondary">OUR MISSION</p>
								<h1>
									We’re giving the elderly the power to live independently at home, on their own terms.
								</h1>
							</div>
							<div className="secondary our-team-splash__our-story-container">
								<p className="secondary our-team-splash__our-story">OUR STORY</p>
							</div>
							<StaticImage placeholder="blurred"
								className="approach-post-right"
								src="../../static/assets/marketing-site/images/our-approach-02.png"
								objectFit='contain'
							/>
						</div>
					</section>
					<section className='our-team-our-story__section'>
						<StaticImage
							className='our-team-our-story__image'
							src="../../static/assets/marketing-site/images/team/team-photo-old.png"
							objectFit='cover'
						/>
						<div className='our-team-our-story__copy'>
							<h3 className='our-team-our-story__copy-heading'>
								We’re Jonny and Oliver, the founders of Edyn. We’re childhood friends who used to work in politics and in finance who founded edyn after our own experiences arranging care for loved ones. 
							</h3>
							<br />
							<p className='our-team-out-story__copy-text'>
								We were shocked to realise how difficult care was in England. It was so hard to arrange with no great tools out there to simplify it, incredibly expensive and common options like care homes just seemed so in-human. For a service which is so intimate the whole process just felt heartless. 
							</p>
							<br />
							<p className='our-team-out-story__copy-text'>
								So we thought we’d try something new. A personal, professional and proactive approach powered by professional carers, the human touch and technology. A service which would be fitting for our own loved ones.Today, we support hundreds of families, providing the most dependable, personalised live-in care to their loved ones. We are a human company first and a technology company second, driven to provide 10 million hours of care by 2024.
							</p>
						</div>
					</section>
					<hr />
					<section className='our-team-logos__section'>
						<div className='our-team-logos__block'>
							<h3 className='our-team-logos__block-title'>
								awards
							</h3>
							<div className='our-team-logos__block-logos'>
								<div className='our-team-logos__logo our-team-logos__health-investor-awards-logo'>
									<StaticImage
										src='../../static/assets/marketing-site/images/our-team-logos/health-investor-awards.png'
										objectFit='contain'
									/>
								</div>
								<div className='our-team-logos__logo our-team-logos__leading-healthcare-awards-logo'>
									<StaticImage
										src='../../static/assets/marketing-site/images/our-team-logos/leading-healthcare-awards.png'
										objectFit='contain'
									/>
								</div>
							</div>
						</div>
						<div className='our-team-logos__block'>
							<h3 className='our-team-logos__block-title'>
								regulated by
							</h3>
							<div className='our-team-logos__block-logos'>
								<div className='our-team-logos__logo our-team-logos__cqc-logo'>
									<StaticImage
										src='../../static/assets/marketing-site/images/our-team-logos/cqc.png'
										objectFit='contain'
									/>
								</div>
							</div>
						</div>
						<div className='our-team-logos__block'>
							<h3 className='our-team-logos__block-title'>
								partners
							</h3>
							<div className='our-team-logos__block-logos'>
								<div className='our-team-logos__logo our-team-logos__skills-for-care-logo'>
									<StaticImage
										src='../../static/assets/marketing-site/images/our-team-logos/skills-for-care.png'
										objectFit='contain'
									/>
								</div>
								<div className='our-team-logos__logo our-team-logos__natwest-logo'>
									<StaticImage
										src='../../static/assets/marketing-site/images/our-team-logos/natwest.png'
										objectFit='contain'
									/>
								</div>
							</div>
						</div>
					</section>
					<section className='our-team-members__section'>
						<svg
							className='our-team-members__top-background'
							viewBox="0 0 321 136"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clipRule="evenodd"
								d="M-205.644 -30.3513C223.054 153.925 289.466 179.029 634.981 71.8005C980.496 -35.4278 574.465 -567.272 574.465 -567.272L-146.756 -1010.76L-325.02 -1088.26C-325.02 -1088.26 -634.342 -214.628 -205.644 -30.3513Z"
								fill="#E5F2E6"
							/>
						</svg>
						<div className='our-team-members__header'>
							<h2 className='our-team-members__title'>
								Meet our team
							</h2>
							<p className='our-team-members__sub-title'>
								We are made up of professional carers, support teams, care managers and technologists building a smart way to care. The first care company built from the bottom up specifically for live-in care which is proud to be regulated by the Care Quality Commission.
							</p>
						</div>
						<div className='our-team-members__grid'>
							<div className='our-team-members__person'>
								<StaticImage
									className='our-team-members__person-image'
									src="../../static/assets/marketing-site/images/team/jonny.png"
								/>
								<p className='our-team-members__person-title'>CEO</p>
								<h4 className='our-team-members__person-name'>Jonny Bottomley</h4>
							</div>
							<div className='our-team-members__person'>
								<StaticImage
									className='our-team-members__person-image'
									src="../../static/assets/marketing-site/images/team/oliver.png"
								/>
								<p className='our-team-members__person-title'>COO</p>
								<h4 className='our-team-members__person-name'>Oliver Ross</h4>
							</div>
							<div className='our-team-members__person'>
								<StaticImage
									className='our-team-members__person-image'
									src="../../static/assets/marketing-site/images/team/maria.png"
								/>
								<p className='our-team-members__person-title'>Recruitment Specialist</p>
								<h4 className='our-team-members__person-name'>Maria Perebzjak</h4>
							</div>
							<div className='our-team-members__person'>
								<StaticImage
									className='our-team-members__person-image'
									src="../../static/assets/marketing-site/images/team/farah.png"
								/>
								<p className='our-team-members__person-title'>Quality Assurance Assistant</p>
								<h4 className='our-team-members__person-name'>Farah Koheeallee</h4>
							</div>
							<div className='our-team-members__person'>
								<StaticImage
									className='our-team-members__person-image'
									src="../../static/assets/marketing-site/images/team/eoin.png"
								/>
								<p className='our-team-members__person-title'>CTO</p>
								<h4 className='our-team-members__person-name'>Eoin McCarthy</h4>
							</div>
							<div className='our-team-members__person'>
								<StaticImage
									className='our-team-members__person-image'
									src="../../static/assets/marketing-site/images/team/tilda.png"
								/>
								<p className='our-team-members__person-title'>Family Care Advisor</p>
								<h4 className='our-team-members__person-name'>Tilda Mew</h4>
							</div>
							<div className='our-team-members__person'>
								<StaticImage
									className='our-team-members__person-image'
									src="../../static/assets/marketing-site/images/team/tamas.png"
								/>
								<p className='our-team-members__person-title'>Integrations Engineer</p>
								<h4 className='our-team-members__person-name'>Tamas Sagi</h4>
							</div>
							<div className='our-team-members__person'>
								<StaticImage
									className='our-team-members__person-image'
									src="../../static/assets/marketing-site/images/team/angela.png"
								/>
								<p className='our-team-members__person-title'>Field Care Supervisor</p>
								<h4 className='our-team-members__person-name'>Angela Lowell</h4>
							</div>
							<div className='our-team-members__person'>
								<StaticImage
									className='our-team-members__person-image'
									src="../../static/assets/marketing-site/images/team/diana.png"
								/>
								<p className='our-team-members__person-title'>Care Coordinator</p>
								<h4 className='our-team-members__person-name'>Diana Majeed</h4>
							</div>
							<div className='our-team-members__person'>
								<StaticImage
									className='our-team-members__person-image'
									src="../../static/assets/marketing-site/images/team/ameen.png"
								/>
								<p className='our-team-members__person-title'>Junior Web Developer</p>
								<h4 className='our-team-members__person-name'>Ameen Ahmed</h4>
								</div>
							<div className='our-team-members__person'>
								<StaticImage
									className='our-team-members__person-image'
									src="../../static/assets/marketing-site/images/team/sam.png"
								/>
								<p className='our-team-members__person-title'>Junior Web Developer</p>
								<h4 className='our-team-members__person-name'>Sam Hunter</h4>
							</div>
              <div className='our-team-members__person'>
								<StaticImage
									className='our-team-members__person-image'
									src="../../static/assets/marketing-site/images/team/benj.png"
								/>
								<p className='our-team-members__person-title'>Family Care Advisor</p>
								<h4 className='our-team-members__person-name'>Benj Faiman</h4>
							</div>
              <div className='our-team-members__person'>
								<StaticImage
									className='our-team-members__person-image'
									src="../../static/assets/marketing-site/images/team/anna.png"
								/>
								<p className='our-team-members__person-title'>Recruitment Specialist</p>
								<h4 className='our-team-members__person-name'>Anna Bondar</h4>
							</div>
              <div className='our-team-members__person'>
								<StaticImage
									className='our-team-members__person-image'
									src="../../static/assets/marketing-site/images/team/orianne.png"
								/>
								<p className='our-team-members__person-title'>Quality Assurance Officer</p>
								<h4 className='our-team-members__person-name'>Orianne Collins</h4>
							</div>
              <div className='our-team-members__person'>
								<StaticImage
									className='our-team-members__person-image'
									src="../../static/assets/marketing-site/images/team/andrew.png"
								/>
								<p className='our-team-members__person-title'>Care Coordinator</p>
								<h4 className='our-team-members__person-name'>Andrew Gabor</h4>
							</div>
							<div className='our-team-members__person'>
								<StaticImage
									className='our-team-members__person-image'
									src="../../static/assets/marketing-site/images/team/daniel.png"
								/>
								<p className='our-team-members__person-title'>Senior Care Advisor</p>
								<h4 className='our-team-members__person-name'>Daniel Greeves</h4>
							</div>
              <div className='our-team-members__person'>
								<StaticImage
									className='our-team-members__person-image'
									src="../../static/assets/marketing-site/images/team/victor.png"
								/>
								<p className='our-team-members__person-title'>Care Coordinator</p>
								<h4 className='our-team-members__person-name'>Victor Akindele</h4>
							</div>
						</div>

						<svg 
							className='our-team-members__bottom-background'
							viewBox="0 0 321 113"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path fillRule="evenodd" clipRule="evenodd" d="M-680.72 515.452C-755.82 470.876 -892.341 328.679 -687.329 224.821C-482.317 120.962 -120.955 -0.996909 41.0377 0.226805C203.03 1.45052 573.864 253.864 589.91 263.568C605.955 273.273 154.557 206.383 170.273 225.664C185.988 244.944 208.125 313.9 163.997 326.23C119.869 338.56 -247.108 420.311 -256.471 421.482C-265.834 422.654 -165.722 499.991 -267.898 530.559C-370.074 561.126 -605.62 560.028 -680.72 515.452Z" fill="#E5F2E6"/>
						</svg>
					</section>

				</main>
				<section className="bac-cta">
                    <div className="bac-cta__card">
                        <StaticImage src="../../static/assets/marketing-site/images/we-care.png" placeholder="blurred" objectFit="contain" />
                        <div className="bac-cta__description">
                            <h1 className="bac-cta__description-title">Join our team</h1>
                            <p className="bac-cta__description-text our-team-bac-cta__description-text">
								We’re building an amazing team to transform the care industry.
                                <br />
								<br />
								See our open positions
                            </p>
                            <Link to={`https://www.linkedin.com/company/edyn-care/jobs/`} target='__blank'>
                                <button className="btn-orange">Open linkedin</button>
                            </Link>
                        </div>
                    </div>
                </section>
				<Footer includeCTA={false} includeBecomeACarer={true}/>
			</div>
		</>
	);
}